<script>
  import { createEventDispatcher, onMount } from 'svelte'
  import { _ } from 'svelte-i18n'
  import { router } from 'tinro'
  import { BASE_URL } from '../../components/DataStore'

  import ResultDisplay2 from './exercise/ResultDisplay2.svelte'
  import SpeechToTextOpus from './exercise/SpeechToTextOpus.svelte'

  import TextToSpeech2 from './exercise/TextToSpeech2.svelte'
  import UploadResult from './exercise/UploadResult.svelte'
  import * as animateScroll from 'svelte-scrollto'
  import Instuctions from './exercise/Instuctions.svelte'

  import ExerciseRead from './ExerciseRead.svelte'
  import ExerciseQuestion from './ExerciseQuestion.svelte'
  import LessonProgressTab from './LessonProgressTab.svelte'
  import UploadResult2 from './exercise/UploadResult2.svelte'
  import SpeechToTextOpus2 from './exercise/SpeechToTextOpus2.svelte'
  import ResultDisplayQuestionWithAnswer from './exercise/ResultDisplayQuestionWithAnswer.svelte'
  import ExerciseReadSSML from './ExerciseReadSSML.svelte'
  import ExerciseV1 from './ExerciseV1.svelte'
  import {exercisePos , exerciseList} from "../../components/DataStore"

  export let loginDetail
  export let exercisePin
  let exerciseDetail = undefined

  let recordings = []
  let textToDisplay = undefined
  let textToRead = undefined

  let submitSuccess = false
  let resultData = undefined

  let recordingButtonState
  let errorMsg

  let retryCounter = 0

  const dispatch = createEventDispatcher()

  onMount(async () => {
    console.log('loginDetail', loginDetail, exercisePin)
    loadData()
  })

  function loadData() {
    errorMsg = ''
    fetch(BASE_URL + `public/api/exercises?key=` + exercisePin)
      .then((response) => {
        if (!response.ok) {
          console.log('response error')

          throw new Error('Network response was not ok', {
            cause: {
              url: response?.url,
              status: response?.status,
              response: response.json(),
            },
          })
        }
        console.log('response ok', response)
        return response.json()
      })
      .then((data) => {
        exerciseDetail = data
        //TODO
        if (exerciseDetail.type === 'READ') {
          textToDisplay = exerciseDetail?.text
        } else {
          textToDisplay = exerciseDetail?.question
        }
        console.log('textToDisplay', textToDisplay)
        textToRead = textToDisplay
        console.log('exericse data', exerciseDetail)
      })
      .catch((error) => {
        console.error('catch error get exercise', error)
        errorMsg = 'Missing PIN'

        if (error.cause) {
          error?.cause?.response?.then((data) => {
            errorMsg = data?.errorMessage
          })
        }
        exerciseDetail = undefined
      })
  }

  function handleOpenLesson(event) {
    console.log('handleOpenLesson', event)
    dispatch('openLesson', event?.detail)
  }

  function handleNextExercise(event) {
    console.log('handleNextExercise', event)
    navigateNextExercise()
  }

  function navigateBackToLesson() {
    router.goto(`lesson/${exerciseDetail?.lessonId}`)
  }

  function navigateNextExercise() {
    if ($exerciseList.length === ($exercisePos +1)) {
      router.goto(`lesson/${exerciseDetail?.lessonId}`)
    } else{
      let ex = $exerciseList[$exercisePos + 1];
      console.log('EX', ex);    
      router.goto(`exercise-open/${ex?.exerciseKey}`)
    }
  }
</script>

{#if exerciseDetail?.lessonId}
  <LessonProgressTab {exerciseDetail} />
{/if}

{#if !exerciseDetail}
  {#if errorMsg}
    <div class="alert alert-danger">{errorMsg}</div>
  {:else}
    Loading ...
  {/if}
{:else if exerciseDetail.type === 'READ' && exerciseDetail.courseReportType === 'REPORT_V2'}
  <ExerciseRead
    on:openLesson={handleOpenLesson}
    on:nextExercise={handleNextExercise}
    {textToDisplay}
    {loginDetail}
    {exerciseDetail}
  />
{:else if exerciseDetail.type === 'QUESTION' || exerciseDetail.type === 'QUESTION_FIX_ANSWER'}
  <ExerciseQuestion
    on:openLesson={handleOpenLesson}
    on:nextExercise={handleNextExercise}
    {textToDisplay}
    {loginDetail}
    {exerciseDetail}
  />
{:else if exerciseDetail?.ttsWords && (exerciseDetail.type === 'READ' || exerciseDetail.type === 'READ_IMAGE') && exerciseDetail.courseReportType === 'REPORT_V1'}
  <ExerciseReadSSML
    on:openLesson={handleOpenLesson}
    on:nextExercise={handleNextExercise}
    {textToDisplay}
    {loginDetail}
    {exerciseDetail}
  />
{:else}
  <ExerciseV1
    on:openLesson={handleOpenLesson}
    on:nextExercise={handleNextExercise}
    {textToDisplay}
    {loginDetail}
    {exerciseDetail}
  />
{/if}
