<script>
  import { createEventDispatcher, onMount } from 'svelte'
  import { getDataOutside } from './Utils.svelte'
  import { cleanCharactersForSSML } from '../../utils/FormatUtils.svelte'
  import { processBlobToWav } from '../../utils/CodecUtils.svelte'

  export let resultData
  export let lastRecording
  export let textToDisplay
  export let loginDetail
  export let exerciseDetail

  const dispatch = createEventDispatcher()
  let errorMsg

  // exercise text
  // replace whitespace characters (spaces, tabs, linebreaks), line feed characters, and carriage returns
  let cleanText = textToDisplay.replace(/\s\s+|\n|\r/g, ' ')
  let textArray = cleanText.split(' ')

  onMount(async () => {
    initializeVoice()
    try {
      initWaveWorm()
    } catch (e) {
      console.error('initWaveWorm', e)
    }

    setDisplayWordForResultData()
  })

  function matchWordWithoutPunctuation(a, b) {
    let a_clean = a
      ?.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()\?]/g, '')
      ?.toLowerCase()
    let b_clean = b
      ?.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()\?]/g, '')
      ?.toLowerCase()
    //console.log(a_clean, b_clean)
    return a_clean === b_clean
    //if (a_clean === b_clean) {return a} else {return b}
  }

  // Goal is to reformat the text to show capitalizations and punctuation
  // Primary Reference Point is `resultData?.data.NBest[0]?.Display`
  // Secondary Reference Point is to use `w.Word`
  function setDisplayWordForResultData() {
    let index = 0
    let displayWordArray = resultData?.data.NBest[0]?.Display.split(' ')

    resultData?.data.NBest[0]?.Words.forEach(function (w, i) {
      // If the word is not of the Omission ErrorType, then we can use the corresponding DisplayText
      if (w.ErrorType !== 'Omission') {
        // First check to see if the Primary Reference Point is empty
        // If it is, we will use the Secondary Reference Point
        if (displayWordArray.length === 1 && displayWordArray[0] === '') {
          resultData.data.NBest[0].Words[i]['DisplayWord'] = w.Word
        } else {
          // Otherwise, we can use the Primary Reference Point and increment it
          if (matchWordWithoutPunctuation(w?.Word, displayWordArray[index])) {
            resultData.data.NBest[0].Words[i]['DisplayWord'] =
              displayWordArray[index]
            index++
          } else {
            resultData.data.NBest[0].Words[i]['DisplayWord'] = w.Word
          }
        }
      } else {
        resultData.data.NBest[0].Words[i]['DisplayWord'] = w.Word
      }
    })
    // // Let's first check to see if the results returned the same number of words
    // // If so, let's set the "DisplayWord" key with the original text prompt as is.
    // if (resultData?.data.NBest[0]?.Words.length === textArray.length) {
    //   resultData?.data.NBest[0]?.Words.forEach(function(w, i) {
    //     resultData.data.NBest[0].Words[i]["DisplayWord"] = textArray[i];
    //   });
    // } else {
    //   // else, based on the WordScore and ErrorType, let's try to set the correct "DisplayWord".
    //   resultData?.data.NBest[0]?.Words.forEach(function(w, i) {
    //     if (w.ErrorType !== "Insertion") {
    //       resultData.data.NBest[0].Words[i]["DisplayWord"] = textArray[index];
    //       index++;
    //     }
    //   });
    // }
    console.log(999, resultData)
  }

  //#region Wavesurfer
  let wavesurfer
  let initWaveFormDone = false
  let playing = false

  async function initWaveWorm() {
    initWaveFormDone = true
    // waveform.innerHTML = "";
    // console.log("initWaveWorm");

    //waveform = document.getElementById("waveform");

    let wavesurferOptions = {
      container: '#waveform',
    }

    wavesurfer = WaveSurfer.create(wavesurferOptions)

    wavesurfer.on('finish', function () {
      playing = false
    })

    wavesurfer.on('pause', function () {
      playing = false
    })

    wavesurfer.on('play', function () {
      playing = true
    })

    let iOS = navigator.vendor == 'Apple Computer, Inc.'
    // console.log("ios", iOS);

    if (!iOS) {
      console.log(lastRecording.blob)
      wavesurfer.loadBlob(lastRecording.blob)
    } else {
      lastRecording.blob = await processBlobToWav(lastRecording.blob)
      wavesurfer.loadBlob(lastRecording.blob)
    }
  }

  function playPauseClick() {
    // console.log("pauseAudio");
    mixpanel.track('Student Results Me Button Clicked')
    if (ttsButtonState === 'playing') {
      stopTextToSpeech()
    }
    wavesurfer.playPause()
    //wavesurfer.play(0);
    playing = wavesurfer.isPlaying()
  }

  function replayWord(selectedWord, i) {
    mixpanel.track('Student Results Word Bubble Button Clicked')

    let startTime = selectedWord.Offset / 10000000
    let endTime = startTime + selectedWord.Duration / 10000000

    if (startTime && endTime) {
      wavesurfer.play(startTime, endTime)
    }

    clickPlayWordTTS(selectedWord.Word)

    // #TODO [SEN-514]

    // #TODO Bouncing Text
    // window.$('span[data-id="'+i+'"]').addClass('badge-active');
    // $: ttsButtonState, window.$('span[data-id="'+i+'"]').removeClass('badge-active');
  }
  //#endregion

  //#region tts
  let subscriptionKey
  let serviceRegion = 'eastasia'
  let authorizationToken = ''
  let SpeechSDK
  let synthesizer
  let player
  let audioConfig
  let ttsToken
  let ttsButtonState = 'starting'
  let speechCounter = 0
  let language = 'en-US'
  const slowFlag = false
  let textToRead
  const languageMapping = {
    'en-US': {
      alternatives: ['en-us'],
      voiceNames: ['en-US-GuyNeural', 'en-US-JennyNeural'],
    },
    'en-GB': {
      alternatives: ['en-gb', 'en-uk', 'en-UK'],
      voiceNames: ['en-GB-LibbyNeural', 'en-GB-RyanNeural'],
    },
    'en-IN': {
      alternatives: ['en-in'],
      voiceNames: ['en-IN-NeerjaNeural', 'en-IN-PrabhatNeural'],
    },
    'en-NG': {
      alternatives: ['en-ng'],
      voiceNames: ['en-NG-AbeoNeural', 'en-NG-EzinneNeural'],
    },
    'en-AU': {
      alternatives: ['en-au'],
      voiceNames: ['en-AU-AnnetteNeural', 'en-AU-DarrenNeural'],
    },
    'zh-TW': {
      alternatives: ['zh-tw'],
      voiceNames: ['zh-TW-HsiaoChenNeural', 'zh-TW-YunJheNeural'],
    },
    'cs-CZ': {
      alternatives: ['cs-cz'],
      voiceNames: ['cs-CZ-VlastaNeural', 'cs-CZ-AntoninNeural'],
    },
    'jp-JP': {
      alternatives: ['jp-JP'],
      voiceNames: ['ja-JP-NanamiNeural', 'ja-JP-KeitaNeural'],
    },
    'zh-CN': {
      alternatives: ['zh-cn'],
      voiceNames: ['zh-CN-XiaoxiaoNeural', 'zh-CN-YunyangNeural'],
    },
    'de-DE': {
      alternatives: ['de-DE'],
      voiceNames: ['de-DE-KatjaNeural', 'de-DE-ConradNeural'],
    },
  }

  function initializeVoice() {
    if (!!window.SpeechSDK) {
      SpeechSDK = window.SpeechSDK
      ttsButtonState = 'starting'

      initPlayPage()
    } else {
      ttsButtonState = 'error'
      // console.log("error with SpeechSDK");
    }
  }

  function initPlayPage() {
    let data = {
      'Ocp-Apim-Subscription-Key': '07314c54f87947ecbb1b93c067a7c463',
    }
    postToken(
      'https://eastasia.api.cognitive.microsoft.com/sts/v1.0/issuetoken',
      data
    )
      .then((data) => {
        // console.log("token", data);
        ttsToken = data
        ttsButtonState = 'ready'
      })
      .catch((error) => {
        // console.error("token", error);
        ttsButtonState = 'error'
      })
  }

  async function postToken(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        //'Content-Type': 'application/json'
        'Content-Type': 'application/x-www-form-urlencoded',
        ...data,
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })
    //console.log("postToken - status", response.status);
    //uploadStatus.innerHTML = response.status;
    return response.text() // parses JSON response into native JavaScript objects
  }

  function playTextToSpeech() {
    if (ttsToken != null) {
      authorizationToken = ttsToken
    } else {
      // console.log("missing token");
      return
    }
    let voiceName

    // if we got an authorization token, use the token. Otherwise use the provided subscription key
    let speechConfig
    if (authorizationToken) {
      speechConfig = SpeechSDK.SpeechConfig.fromAuthorizationToken(
        authorizationToken,
        serviceRegion
      )
    } else {
      if (
        subscriptionKey.value === '' ||
        subscriptionKey.value === 'subscription'
      ) {
        alert(
          'Please enter your Microsoft Cognitive Services Speech subscription key!'
        )
        ttsButtonState = 'error'
        return
      }
      speechConfig = SpeechSDK.SpeechConfig.fromSubscription(
        subscriptionKey.value,
        serviceRegion
      )
    }
    // console.log("language", language);
    language = exerciseDetail?.courseLanguage
      ? exerciseDetail?.courseLanguage
      : language

    if (!languageMapping[language]) {
      for (const [key, value] of Object.entries(languageMapping)) {
        const found = value.alternatives.find((el) => el == language)
        if (found) {
          language = key
          // console.log(found);
        }
      }
    }
    speechConfig.speechSynthesisLanguage = language

    if (languageMapping[language]) {
      let numberOfVoice = languageMapping[language].voiceNames.length
      let selectedVoice =
        languageMapping[language].voiceNames[speechCounter % 2]
      // console.log("numberOfVoice", numberOfVoice);
      // console.log("selectedVoice", selectedVoice);
      speechConfig.speechSynthesisVoiceName = selectedVoice
      voiceName = selectedVoice
    } else {
      // console.log("Error no language is available");
    }

    speechCounter++

    //speechConfig.speechSynthesisOutputFormat = SpeechSDK.SpeechSynthesisOutputFormat.Ogg16Khz16BitMonoOpus;

    player = new SpeechSDK.SpeakerAudioDestination()
    audioConfig = SpeechSDK.AudioConfig.fromSpeakerOutput(player)
    synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig)

    ttsButtonState = 'playing'

    let ssml =
      `<speak xmlns="http://www.w3.org/2001/10/synthesis"
      xmlns:mstts="http://www.w3.org/2001/mstts"
      xmlns:emo="http://www.w3.org/2009/10/emotionml"
      version="1.0"
      xml:lang="` +
      language +
      `">
    <voice name="` +
      voiceName +
      `">
    <prosody rate="` +
      (slowFlag ? '-40%' : '-20%') +
      `"
            pitch="0%">` +
      cleanCharactersForSSML(textToRead) +
      `</prosody>
    </voice>
    </speak>`

    synthesizer.speakSsmlAsync(
      ssml,
      function (result) {
        console.log(result)
        if (
          result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted
        ) {
          console.log('synthesis finished for [' + textToRead + ']')
        } else if (result.reason === SpeechSDK.ResultReason.Canceled) {
          console.log('synthesis failed. Error detail: ' + result.errorDetails)
          ttsButtonState = 'error'
        }
        synthesizer.close()
        synthesizer = undefined
      },
      function (err) {
        ttsButtonState = 'error'
        // console.log("Synthesizer Error", err);

        synthesizer.close()
        synthesizer = undefined
      }
    )

    player.onAudioEnd = function (s) {
      console.log('onAudioEnd', s)
      ttsButtonState = 'ready'
    }
  }

  export function stopTextToSpeech() {
    console.log(player)
    if (player != null) {
      player.pause()
      ttsButtonState = 'ready'
    }
  }

  function clickPlayTTS() {
    mixpanel.track('Student Results Teacher Button Clicked')
    textToRead = textToDisplay
    stopTextToSpeech()
    playTextToSpeech()
  }

  function clickPlayWordTTS(word) {
    textToRead = word
    stopTextToSpeech()
    playTextToSpeech()
  }
  //#endregion

  function tryAgain() {
    mixpanel.track('Student Results Try Again Button Clicked')
    dispatch('tryAgainExercise', {})
    //window.location.reload();
  }

  function openLesson() {
    console.log('openLesson')
    const msg = {
      text: 'PIN OK',
      pin: Number(loginDetail?.lessonId),
      userName: loginDetail?.userName,
      loginToken: loginDetail?.loginToken,
      lessonId: Number(loginDetail?.lessonId),
    }
    dispatch('openLesson', msg)
  }

  function nextExercise() {
    mixpanel.track('Student Results Next Button Clicked')
    const msg = {}
    dispatch('nextExercise', msg)
  }

  // Star Animation
  setTimeout(function () {
    window.$('.star.checked').addClass('animate')
  }, 1000)
</script>

<div>
  <div class="container">
    <div class="row exercise-container no-gutters">
      {#if textArray.length < 20}
        <div class="instructions-sub">Click on a word to listen to it.</div>
        <div class="col-10">
          {#each resultData?.data.NBest[0]?.Words as w, i}
            {#if w.ErrorType !== 'Insertion'}
              {#if w.SensayWordScore}
                {#if w.SensayWordScore > 90 && w.ErrorType === 'None'}
                  <span
                    class="badge badge-excellent"
                    on:click={() => replayWord(w, i)}
                    data-id={i}>{w.DisplayWord}</span
                  >
                {:else if w.ErrorType === 'Mispronunciation'}
                  <span
                    class="badge badge-miss"
                    on:click={() => replayWord(w, i)}
                    data-id={i}>{w.DisplayWord}</span
                  >
                {:else if w.ErrorType === 'Omission'}
                  <span
                    class="badge badge-error"
                    on:click={() => replayWord(w, i)}
                    data-id={i}>{w.DisplayWord}</span
                  >
                {:else if w.SensayWordScore > 70}
                  <span
                    class="badge badge-good"
                    on:click={() => replayWord(w, i)}
                    data-id={i}>{w.DisplayWord}</span
                  >
                {:else if w.SensayWordScore > 50}
                  <span
                    class="badge badge-fair"
                    on:click={() => replayWord(w, i)}
                    data-id={i}>{w.DisplayWord}</span
                  >
                {:else if w?.ErrorType != 'None'}
                  <span
                    class="badge badge-excellent"
                    on:click={() => replayWord(w, i)}
                    data-id={i}>{w.DisplayWord}</span
                  >
                {:else}
                  <span
                    span
                    class="badge badge-bad"
                    on:click={() => replayWord(w, i)}
                    data-id={i}>{w.DisplayWord}</span
                  >
                {/if}
              {:else if w.AccuracyScore > 90 && w.ErrorType === 'None'}
                <span
                  class="badge badge-excellent"
                  on:click={() => replayWord(w, i)}
                  data-id={i}>{w.DisplayWord}</span
                >
              {:else if w.ErrorType === 'Mispronunciation'}
                <span
                  class="badge badge-miss"
                  on:click={() => replayWord(w, i)}
                  data-id={i}>{w.DisplayWord}</span
                >
              {:else if w.ErrorType === 'Omission'}
                <span
                  class="badge badge-error"
                  on:click={() => replayWord(w, i)}
                  data-id={i}>{w.DisplayWord}</span
                >
              {:else if w.AccuracyScore > 70}
                <span
                  class="badge badge-good"
                  on:click={() => replayWord(w, i)}
                  data-id={i}>{w.DisplayWord}</span
                >
              {:else if w.AccuracyScore > 50}
                <span
                  class="badge badge-fair"
                  on:click={() => replayWord(w, i)}
                  data-id={i}>{w.DisplayWord}</span
                >
              {:else if w?.ErrorType !== 'None'}
                <span
                  class="badge badge-bad"
                  on:click={() => replayWord(w, i)}
                  data-id={i}>{w.DisplayWord}</span
                >
              {:else}
                <span
                  class="badge badge-bad"
                  on:click={() => replayWord(w, i)}
                  data-id={i}>{w.DisplayWord}</span
                >
              {/if}
            {/if}
          {/each}
        </div>
        <div class="col-2 justify-content-center">
          <div class="results-legend" />
        </div>
      {:else}
        <div class="col-12">
          {#each resultData?.data.NBest[0]?.Words as w, i}
            {#if w.ErrorType !== 'Insertion'}
              {#if w.SensayWordScore}
                {#if w.SensayWordScore > 90 && w.ErrorType === 'None'}
                  <span
                    class="longform longform-excellent"
                    on:click={() => replayWord(w, i)}
                    data-id={i}>{w.DisplayWord}</span
                  >
                {:else if w.ErrorType === 'Mispronunciation' || w.ErrorType === 'Insertion'}
                  <span
                    class="longform longform-miss"
                    on:click={() => replayWord(w, i)}
                    data-id={i}>{w.DisplayWord}</span
                  >
                {:else if w.ErrorType === 'Omission'}
                  <span
                    class="longform longform-error"
                    on:click={() => replayWord(w, i)}
                    data-id={i}>{w.DisplayWord}</span
                  >
                {:else if w.SensayWordScore > 70}
                  <span
                    class="longform longform-good"
                    on:click={() => replayWord(w, i)}
                    data-id={i}>{w.DisplayWord}</span
                  >
                {:else if w.SensayWordScore > 50}
                  <span
                    class="longform longform-fair"
                    on:click={() => replayWord(w, i)}
                    data-id={i}>{w.DisplayWord}</span
                  >
                {:else if w?.ErrorType != 'None'}
                  <span
                    class="longform longform-excellent"
                    on:click={() => replayWord(w, i)}
                    data-id={i}>{w.DisplayWord}</span
                  >
                {:else}
                  <span
                    span
                    class="longform longform-bad"
                    on:click={() => replayWord(w, i)}
                    data-id={i}>{w.DisplayWord}</span
                  >
                {/if}
              {:else if w.AccuracyScore > 90 && w.ErrorType === 'None'}
                <span
                  class="longform longform-excellent"
                  on:click={() => replayWord(w, i)}
                  data-id={i}>{w.DisplayWord}</span
                >
              {:else if w.ErrorType === 'Mispronunciation' || w.ErrorType === 'Insertion'}
                <span
                  class="longform longform-miss"
                  on:click={() => replayWord(w, i)}
                  data-id={i}>{w.DisplayWord}</span
                >
              {:else if w.ErrorType === 'Omission'}
                <span
                  class="longform longform-error"
                  on:click={() => replayWord(w, i)}
                  data-id={i}>{w.DisplayWord}</span
                >
              {:else if w.AccuracyScore > 70}
                <span
                  class="longform longform-good"
                  on:click={() => replayWord(w, i)}
                  data-id={i}>{w.DisplayWord}</span
                >
              {:else if w.AccuracyScore > 50}
                <span
                  class="longform longform-fair"
                  on:click={() => replayWord(w, i)}
                  data-id={i}>{w.DisplayWord}</span
                >
              {:else if w?.ErrorType !== 'None'}
                <span
                  class="longform longform-bad"
                  on:click={() => replayWord(w, i)}
                  data-id={i}>{w.DisplayWord}</span
                >
              {:else}
                <span
                  class="longform longform-bad"
                  on:click={() => replayWord(w, i)}
                  data-id={i}>{w.DisplayWord}</span
                >
              {/if}
            {/if}
          {/each}
        </div>
      {/if}
    </div>

    <div class="row justify-content-center playback-container">
      <div class="col-6 col-md-4 d-flex justify-content-center">
        <button
          class="btn btn-primary play"
          on:click={playPauseClick}
          type="button"
        >
          {#if playing}
            <i class="fa fa-pause" style="margin: 0px 3px;" />
          {:else}
            <i class="fa fa-play" style="margin: 0px 3px;" />
          {/if}
          Me
        </button>
      </div>

      <div class="col-6 col-md-4 d-flex justify-content-center">
        {#if ttsButtonState === 'starting'}
          TTS initializing...
        {:else if ttsButtonState === 'error'}
          TTS Error!
        {:else if ttsButtonState === 'playing'}
          <button
            class="btn teacher"
            on:click={() => stopTextToSpeech()}
            type="button"
          >
            <i class="fa fa-pause" style="margin: 0px 3px;" />
            Teacher
          </button>
        {:else}
          <button
            class="btn teacher"
            on:click={() => clickPlayTTS()}
            type="button"
          >
            <i class="fa fa-headphones" style="margin: 0px 3px;" />
            Teacher
          </button>
        {/if}
      </div>
    </div>

    <div class="row justify-content-center score-container">
      <div
        class="col-6 col-md-4 d-flex justify-content-center"
        style="flex-direction:column; align-items: center;"
      >
        <div>
          <span class="numerical-score">
            {Number.parseFloat(resultData?.overallScore).toFixed(0)}
          </span>
        </div>
        <div>
          <div class="rating">
            <div
              class="star"
              class:checked={Number.parseFloat(
                resultData?.overallScore
              ).toFixed(0) > 30}
            />
            <div
              class="star"
              class:checked={Number.parseFloat(
                resultData?.overallScore
              ).toFixed(0) > 50}
            />
            <div
              class="star"
              class:checked={Number.parseFloat(
                resultData?.overallScore
              ).toFixed(0) > 65}
            />
            <div
              class="star"
              class:checked={Number.parseFloat(
                resultData?.overallScore
              ).toFixed(0) > 80}
            />
            <div
              class="star"
              class:checked={Number.parseFloat(
                resultData?.overallScore
              ).toFixed(0) > 90}
            />
          </div>
        </div>
      </div>
      <div
        class="col-6 col-md-4 d-flex justify-content-center"
        style="flex-direction:column; align-items: center;"
      >
        <div class="smiley-score-container">
          {#if Number.parseFloat(resultData?.overallScore).toFixed(0) <= 25}
            <img
              src="../assets/img/expressionless-face-people.gif"
              alt="expressless-face-emoji"
              class="smiley-score"
            />
          {:else if Number.parseFloat(resultData?.overallScore).toFixed(0) <= 79}
            <img
              src="../assets/img/thinking-face-joypixels.gif"
              alt="thinking-face-emoji"
              class="smiley-score"
            />
          {:else if Number.parseFloat(resultData?.overallScore).toFixed(0) <= 94}
            <img
              src="../assets/img/winking-face-joypixels.gif"
              alt="winking-face-emoji"
              class="smiley-score"
            />
          {:else}
            <img
              src="../assets/img/starstruck-joypixels.gif"
              alt="starstruke-emoji"
              class="smiley-score"
            />
          {/if}
        </div>
      </div>
    </div>

    <div class="row justify-content-center segue-container">
      <div class="col-6 col-md-4 d-flex justify-content-center">
        <button class="btn try-again" on:click={tryAgain} type="button">
          <i class="fas fa-repeat" style="margin: 0px 3px;" />
          Try Again
        </button>
      </div>
      <div class="col-6 col-md-4 d-flex justify-content-center">
        <!-- {#if loginDetail.lessonId} -->
        <button
          class="btn btn-primary next"
          on:click={nextExercise}
          type="button"
        >
          <i class="fas fa-arrow-right" style="margin: 0px 3px;" />
          Next
        </button>
        <!-- {/if} -->
      </div>
    </div>

    <div class="container detailed-scores">
      <section>
        <div>
          <div class="icon baseline">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              fill="none"
              viewBox="0 0 21 20"
            >
              <path
                fill="#000"
                fill-opacity=".54"
                stroke="#fff"
                stroke-width=".15"
                d="M5.074 14.925h-.03l-.023.022-3.205 3.205V3.333c0-.875.716-1.591 1.592-1.591H16.74c.875 0 1.592.716 1.592 1.591v10c0 .876-.717 1.592-1.592 1.592H5.074Zm11.667-1.517h.075V3.258H3.333v11.923l.128-.128 1.644-1.645h11.636ZM5.983 7.575h1.516v1.517H5.983V7.575Zm3.333 0h1.517v1.517H9.316V7.575Zm3.333 0h1.517v1.517h-1.517V7.575Z"
              />
            </svg>
          </div>
          Pronunciation
        </div>
        <div class="score">
          {Number.parseFloat(resultData?.pronScore).toFixed(0)}
        </div>
      </section>
      <section>
        <div>
          <div class="icon baseline">
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 2.5C10.2563 2.5 12.5 4.74375 12.5 7.5C12.5 10.2563 10.2563 12.5 7.5 12.5C6.7625 12.5 6.0375 12.3375 5.35625 12.0125C5.1875 11.9312 5.00625 11.8938 4.81875 11.8938C4.7 11.8938 4.58125 11.9125 4.46875 11.9438L2.46875 12.5312L3.05625 10.5312C3.14375 10.2375 3.11875 9.91875 2.9875 9.64375C2.6625 8.9625 2.5 8.2375 2.5 7.5C2.5 4.74375 4.74375 2.5 7.5 2.5ZM7.5 1.25C4.05 1.25 1.25 4.05 1.25 7.5C1.25 8.4625 1.475 9.3625 1.85625 10.1813L0.625 14.375L4.81875 13.1438C5.6375 13.525 6.5375 13.75 7.5 13.75C10.95 13.75 13.75 10.95 13.75 7.5C13.75 4.05 10.95 1.25 7.5 1.25Z"
                fill="#323232"
              />
              <circle cx="6.25" cy="6.25" r="0.625" fill="black" />
              <circle cx="9.375" cy="6.25" r="0.625" fill="black" />
              <path
                d="M6.25 9.0625C6.77083 9.6875 8.1875 10.5625 9.6875 9.0625"
                stroke="black"
              />
            </svg>
          </div>
          Fluency
        </div>
        <div class="score">
          {Number.parseFloat(resultData?.fluencyScore).toFixed(0)}
        </div>
      </section>
    </div>

    <div id="waveform" style="display:none" />
  </div>
</div>

<style>
  .instructions-sub {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 1em;

    color: #5e5e61;

    margin-bottom: 1vh;
  }

  /* .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }
  :global(.no-gutters > .col),
  .no-gutters > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  } */

  .exercise-container {
    border-top: 0.5px dashed rgba(0, 0, 0, 0.3);
    padding-top: 1vh;
    margin: 1vh 0 0 0;
  }

  .playback-container {
    padding: 1vh 0 1vh 0;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
  }

  .score-container {
    margin-top: 0vh;
  }

  .segue-container {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
    padding-bottom: 1vh;
  }

  /* Exercise Text */
  .badge {
    float: left;
    padding: 0px 2vh 0px 2vh;
    margin: 2vh 2vh 2vh 0;

    background: #e5f5f4;
    border: 1px solid #00a094;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0px 4px 0 #00a094;

    justify-content: center;

    font-family: Roboto Slab;
    font-style: normal;
    font-weight: normal;
    font-size: 1.3em;
    line-height: 2em;
    color: #000000;

    cursor: pointer;

    position: relative;
  }

  /* .badge:hover:before,
.badge:active:before,
:global(.badge-active:before) {
  content: "";
  position: absolute;
  top: -20px;
  background: #5E5E61;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  left: calc(50% - 5px);
} */

  .badge-excellent,
  .badge-good {
    background: #35b075;
    border: 1px solid #266b4a;
    box-shadow: 0px 4px 0 #266b4a;
    color: ivory;
  }

  .badge-excellent:hover,
  .badge-excellent:active,
  .badge-good:hover,
  .badge-good:active {
    background: #258255;
    border: 1px solid #224333;
    box-shadow: 0px 4px 0 #224333;
    color: ivory;
  }

  .badge-fair {
    background: #f6b95c;
    border: 1px solid #d49027;
    box-shadow: 0px 4px 0 #d49027;
    color: ivory;
  }

  .badge-fair:hover,
  .badge-fair:active {
    background: #e0a03d;
    border: 1px solid #976924;
    box-shadow: 0px 4px 0 #976924;
    color: ivory;
  }

  .badge-bad {
    background: #dd4040;
    border: 1px solid #9b3232;
    box-shadow: 0px 4px 0 #9b3232;
    color: ivory;
  }

  .badge-bad:hover,
  .badge-bad:active {
    background: #ac2a2a;
    border: 1px solid #791c1c;
    box-shadow: 0px 4px 0 #791c1c;
    color: ivory;
  }

  .badge-error {
    background: gray;
    border: 1px solid gray;
    box-shadow: 0px 4px 0 rgb(60, 56, 56, 1);
    color: ivory;
    text-decoration: line-through;
  }

  .badge-miss {
    background: gray;
    border: 1px solid gray;
    box-shadow: 0px 4px 0 rgb(60, 56, 56, 1);
    color: ivory;
  }

  .longform {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 400;
    font-size: 1.3em;

    margin: 1vh 1vh 1vh 0;
    float: left;

    cursor: pointer;
  }

  .longform-excellent,
  .longform-good {
    color: rgba(0, 0, 0, 0.75);

    background-image: linear-gradient(#00a094, #00a094);
    background-position: 0 90%; /* determines how far from bottom */
    background-repeat: no-repeat;
    background-size: 100% 1px; /* second value determines height of border */
  }

  .longform-excellent:hover,
  .longform-excellent:active,
  .longform-good:hover,
  .longform-good:active {
    color: #00a094;
  }

  .longform-fair {
    color: #f3aa3b;

    background-image: linear-gradient(#f3aa3b, #f3aa3b);
    background-position: 0 90%; /* determines how far from bottom */
    background-repeat: no-repeat;
    background-size: 100% 1px; /* second value determines height of border */
  }

  .longform-fair:hover,
  .longform-fair:active {
    color: #d49027;

    background-image: linear-gradient(#d49027, #d49027);
    background-position: 0 90%; /* determines how far from bottom */
    background-repeat: no-repeat;
    background-size: 100% 1px; /* second value determines height of border */
  }

  .longform-bad {
    color: #dd4040;

    background-image: linear-gradient(#dd4040, #dd4040);
    background-position: 0 90%; /* determines how far from bottom */
    background-repeat: no-repeat;
    background-size: 100% 1px; /* second value determines height of border */
  }

  .longform-bad:hover,
  .longform-bad:active {
    color: #9b3232;

    background-image: linear-gradient(#9b3232, #9b3232);
    background-position: 0 90%; /* determines how far from bottom */
    background-repeat: no-repeat;
    background-size: 100% 1px; /* second value determines height of border */
  }

  .longform-error {
    color: gray;
    text-decoration: line-through;

    background-image: linear-gradient(gray, gray);
    background-position: 0 90%; /* determines how far from bottom */
    background-repeat: no-repeat;
    background-size: 100% 1px; /* second value determines height of border */
  }

  .longform-miss {
    color: gray;

    background-image: linear-gradient(gray, gray);
    background-position: 0 90%; /* determines how far from bottom */
    background-repeat: no-repeat;
    background-size: 100% 1px; /* second value determines height of border */
  }

  .numerical-score {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: 500;
    font-size: 4em;

    color: #323232;
  }

  .star {
    display: inline-block;
    margin: 5px;
    font-size: 2em;
    color: black;
    position: relative;
  }

  .star:before {
    font-family: fontAwesome;
    display: inline-block;
    content: '\f005';
    position: relative;
    /* float: right; */
    z-index: 2;
  }

  @keyframes stretch-bounce {
    0% {
      -webkit-transform: scale(1);
    }
    25% {
      -webkit-transform: scale(1.5);
    }
    50% {
      -webkit-transform: scale(0.9);
    }
    75% {
      -webkit-transform: scale(1.2);
    }
    100% {
      -webkit-transform: scale(1);
    }
  }

  .rating :global(.animate) {
    animation: stretch-bounce 0.5s ease-in-out var(--timing);
    transition: color 0s ease var(--timing);
    color: #f6b95c !important;
  }

  .rating .star:nth-child(1) {
    --timing: 1s;
  }

  .rating .star:nth-child(2) {
    --timing: 1.2s;
  }

  .rating .star:nth-child(3) {
    --timing: 1.4s;
  }

  .rating .star:nth-child(4) {
    --timing: 1.6s;
  }

  .rating .star:nth-child(5) {
    --timing: 1.8s;
  }

  .results-legend {
    height: 146px;
    width: 60px;
    position: relative;
    float: right;
    background: url(../assets/img/Report-TrafficLightSystem.svg);
    background-repeat: no-repeat;
  }

  .play {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: normal;
    font-size: 1.3em;

    border-radius: 16px;
    line-height: 1.6em;

    background: #00a094;
    color: #ffffff;
  }

  .teacher {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: normal;
    font-size: 1.3em;

    border-radius: 16px;
    line-height: 1.6em;

    background: #00a094;
    color: #ffffff;
  }

  .try-again {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: normal;
    font-size: 1.2em;

    border-radius: 16px;
    line-height: 1.6em;

    background: #cacaca;
    color: #000000;
  }

  .next {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: normal;
    font-size: 1.3em;

    border-radius: 16px;
    line-height: 1.6em;

    background: #00a094;
    color: #ffffff;
  }

  .detailed-scores {
    margin-top: 1vh;

    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: normal;
    font-size: 1.2em;

    color: #323232;
  }

  .detailed-scores > section {
    display: flex;
    align-items: baseline;

    margin-bottom: 10px;
  }

  .detailed-scores > section > * {
    padding: 0;
    margin: 0;
  }

  .detailed-scores > section > .score {
    flex-grow: 1;
    text-align: right;
    display: flex;
  }

  .detailed-scores > section > .score::before {
    content: '';
    background: repeating-linear-gradient(
      to right,
      currentColor,
      currentColor 1px,
      transparent 2px,
      transparent 4px
    );
    height: 1px;
    flex-grow: 1;
    display: inline-block;
    margin-top: 1em;
  }

  .icon {
    display: inline-flex;
    align-self: center;
  }

  .icon svg {
    height: 1em;
    width: 1em;
    fill: currentColor;
  }

  .icon.baseline svg {
    top: 0.125em;
    position: relative;
  }

  .smiley-score-container {
    display: flex;
    justify-content: center;
  }

  .smiley-score {
    width: 60%;
    padding-bottom: 3vh;
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-device-width: 600px) {
    .detailed-scores {
      font-size: 0.9em;
      padding: 20px;
    }

    .star {
      font-size: 1.5em;
      margin: 1px;
    }

    .col-6,
    .col-md-4 {
      padding-right: 0.25rem;
      padding-left: 0.25rem;
    }

    .badge {
      font-size: 1.2em;
      margin: 0 1vh 1vh 0;
      padding: 0 1vh 0 1vh;
    }

    .playback-container {
      padding-bottom: 1vh;
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .col-6,
    .col-md-4 {
      padding-right: 0.25rem;
      padding-left: 0.25rem;
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .badge {
      padding: 0 1vh 0 1vh;
      margin: 1vh 1vh 0 0;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .exercise-container {
      padding-top: 4vh;
    }

    .segue-container {
      padding-bottom: 2vh;
    }

    .numerical-score {
      font-size: 5em;
    }

    .smiley-score {
      width: 40%;
      padding-bottom: 5vh;
    }

    .detailed-scores {
      margin-top: 3vh;
      padding-bottom: 6vh;
    }
  }
</style>
