<script>
  import { _ } from "svelte-i18n";
  import { router } from "tinro";
  import Loader from "../../components/Loader.svelte";
  import { getData, postData } from "../../utils/ApiUtils.svelte";
  import { onMount } from "svelte";
  import { userDetails } from "../../components/DataStore";
  import Tooltip from "../../components/Tooltip.svelte";
  import { BASE_URL } from "../../components/DataStore";
  import { average } from "../../utils/FormatUtils.svelte";

  let analyticsResultList = [];

  let analyticsResultStatus = "loading";

  let analyticsType = "WORD";
  let filterItem = "";

  onMount(() => {
    getAnalyticsResultList();
  });

  function getAnalyticsResultList() {
    if ($userDetails?.login) {
      analyticsResultStatus = "loading";

      let analyticsTypeApi;
      if (analyticsType === "WORD") {
        analyticsTypeApi = "words";
      } else if (analyticsType === "SYLLABLE") {
        analyticsTypeApi = "syllable";
      } else if (analyticsType === "PHONEME") {
        analyticsTypeApi = "phoneme";
      }

      getData(`${BASE_URL}studentapi/analytics/${analyticsTypeApi}`, true)
        .then((data) => {
          console.log("course successful", data);

          if (filterItem && filterItem.length > 0) {
            data = data.filter((s) => {
              return s?.item?.includes(filterItem);
            });
          }

          data.forEach((el) => {
            if (el.count > 50) {
              let avgPart1 = average(el.values.slice(0, el.count / 4));
              let avgPart2 = average(
                el.values.slice((el.count * 3) / 4, el.count),
              );

              el["trend"] = avgPart2 - avgPart1;
            }

            if (el.count > 200) {
              el.values = sampleSize(el.values, 200);
            }
          });

          analyticsResultList = data;
          analyticsResultStatus = "ok";
        })
        .catch((error) => {
          analyticsResultStatus = "error";
          console.log("error", error);
        });
    }
  }

  const sampleSize = (arr, n = 1) => {
    const result = [];
    const len = arr.length;

    // Generate n unique random indices
    const indices = new Set();
    while (indices.size < n) {
      indices.add(Math.floor(Math.random() * len));
    }

    // Sort the indices to maintain the original sequence
    const sortedIndices = Array.from(indices).sort((a, b) => a - b);

    // Collect elements at the sorted indices
    sortedIndices.forEach((index) => {
      result.push(arr[index]);
    });

    return result;
  };
</script>

<main>
  <div class="container-fluid px-4">
    <h1 class="mt-4">{$_("ANALYTICS_SECTION.ANALYTICS")}</h1>
    <p>{$_("ANALYTICS_SECTION.SENSAY_ANALYTICS_INTRODUCTION")}</p>
    <!-- <div><UpgradeToPremium bind:this={upgradeModal} /></div> -->

    <div class="row">
      <div style="display: flex;gap: 2em;">
        <div class="form-check">
          <label for="courseNameFilterInput" class="form-check-label"
            >{$_("ANALYTICS_SECTION.SELECT_TYPE")}</label
          >
          <select
            class="form-control"
            bind:value={analyticsType}
            on:change={getAnalyticsResultList}
          >
            <option value="WORD">{$_("ANALYTICS_SECTION.WORDS")}</option>
            <option value="SYLLABLE">{$_("ANALYTICS_SECTION.SYLLABLE")}</option>
            <option value="PHONEME">{$_("ANALYTICS_SECTION.PHONEME")}</option>
          </select>
        </div>
        <div class="form-check">
          <label for="studentNameFilterInput" class="form-check-label"
            >{$_(`ANALYTICS_SECTION.${analyticsType}`)}</label
          >
          <input
            id="studentNameFilterInput"
            class="form-control s-form-input"
            type="text"
            style="min-width: 20em;"
            bind:value={filterItem}
            on:change={getAnalyticsResultList}
          />
        </div>
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col-8-auto">
        {#if analyticsResultStatus === "loading"}
          <Loader />
        {:else if analyticsResultStatus === "error"}
          {$_("ANALYTICS_SECTION.ERROR_LOADING_ANALYTICS")}
        {:else}
          <table class="table">
            <tr
              ><th scope="col">#</th>
              <th scope="col">{$_(`ANALYTICS_SECTION.${analyticsType}`)}</th>
              <th scope="col">
                <Tooltip tooltipText={$_("ANALYTICS_SECTION.COUNT_TOOLTIP")}
                  >{$_("ANALYTICS_SECTION.COUNT")}</Tooltip
                ></th
              >
              <!-- <th scope="col"
                ><Tooltip tooltipText={$_("ANALYTICS_SECTION.MIN_TOOLTIP")}
                  >{$_("ANALYTICS_SECTION.MIN")}</Tooltip
                ></th
              > -->
              <th scope="col">
                <Tooltip tooltipText={$_("ANALYTICS_SECTION.MEAN_TOOLTIP")}
                  >{$_("ANALYTICS_SECTION.MEAN")}</Tooltip
                ></th
              >
              <!-- <th scope="col">
                <Tooltip tooltipText={$_("ANALYTICS_SECTION.MAX_TOOLTIP")}
                  >{$_("ANALYTICS_SECTION.MAX")}</Tooltip
                ></th
              > -->
              <th scope="col">{$_("ANALYTICS_SECTION.TREND")} </th>
              <th scope="col">{$_("ANALYTICS_SECTION.VISUALIZATION")}</th>
            </tr>
            <tbody>
              {#each analyticsResultList as exam, i}
                <tr>
                  <th scope="row">{i + 1}</th>
                  <td>{exam?.item}</td>
                  <td>{exam?.count}</td>
                  <!-- <td>{exam?.min}</td> -->
                  <td>{exam?.mean.toFixed(0)}</td>
                  <td class="result"
                    ><span
                      class:result-excellent={exam?.trend > 0}
                      class:result-bad={exam?.trend < 0}
                    >
                      {exam?.trend?.toFixed(0) || ""}
                      {#if exam?.trend > 0}↗{:else if exam?.trend < 0}↘{/if}</span
                    ></td
                  >
                  <!-- <td>{exam?.max}</td> -->
                  <td
                    ><div class="visualization-wrapper">
                      {#each exam?.values.slice(0, 200) as x}
                        {#if x >= 90}
                          <span class="point-excellent" />
                        {:else if x >= 80}
                          <span class="point-green" />
                        {:else if x >= 60}
                          <span class="point-orange" />
                        {:else}
                          <span class="point-red" />
                        {/if}
                      {/each}
                    </div></td
                  >
                </tr>
              {:else}
                {$_("ANALYTICS_SECTION.NO_DATA")}
              {/each}
            </tbody>
          </table>
        {/if}
      </div>
    </div>
  </div>
</main>

<style>
  .visualization-wrapper {
    display: flex;
  }

  .point-excellent {
    width: 2px;
    height: 1em;
    background-color: #008154;
  }

  .point-green {
    width: 2px;
    height: 1em;
    background-color: #40ac86;
  }

  .point-orange {
    width: 2px;
    height: 1em;
    background-color: #f7ba31;
  }

  .point-red {
    width: 2px;
    height: 1em;
    background-color: #fe5b5b;
  }

  .result .result-excellent {
    background-color: #008154;
    color: aliceblue;
    min-width: 1.5em;
    min-height: 1.5em;
    padding: 2px;
  }

  .result .result-good {
    background-color: #40ac86;
    color: aliceblue;
    min-width: 1.5em;
    min-height: 1.5em;
  }

  .result .result-poor {
    background-color: #f7ba31;
    min-width: 1.5em;
    min-height: 1.5em;
  }

  .result .result-bad {
    background-color: #fe5b5b;
    color: aliceblue;
    min-width: 1.5em;
    min-height: 1.5em;
    padding: 2px;
  }

  .course-card-body {
    min-height: 4rem;
  }
</style>
