<script>
  import { _ } from 'svelte-i18n'
  import { router } from 'tinro'
  import Loader from '../../components/Loader.svelte'
  import { onMount } from 'svelte'
  import { writable } from 'svelte/store'
  import {
    getData,
    getDataOutside,
    deleteData,
    patchData,
  } from '../../utils/ApiUtils.svelte'
  import {
    convertSecToMin,
    formatNumberWith4Colors,
  } from '../../utils/FormatUtils.svelte'
  import { processBlobToWav } from '../../utils/CodecUtils.svelte'
  import PhonemeBreakdown from './PhonemeBreakdown.svelte'
  import QuestionWithAnswerDetail from './QuestionWithAnswerDetail.svelte'
    import RecordingTeachingComments from './RecordingTeachingComments.svelte';

  export let recordingId

  let showAudioVisualization = false
  let showDebug = false
  let recordingData = {}
  let recordingDataDetail = { text: '' }
  let iOS = navigator.vendor == 'Apple Computer, Inc.'
  let exerciseData = {}

  const starLevels = {
    1: 30,
    2: 50,
    3: 65,
    4: 80,
    4: 90,
  }

  onMount(async () => {
    getRecording(recordingId)
  })

  let recordingDetailStatus = 'loading'

  let recordingUrl
  //= "https://www.googleapis.com/download/storage/v1/b/shuoshuo/o/recordings%2FR_neVHyH_READ_Caren_2021-02-03T15:14:24.615Z.wav?generation=1612365266671798&alt=media";

  function getExercise(courseId, lessonId, exerciseId) {
    getData(
      'studentapi/courses/' +
        courseId +
        '/lessons/' +
        lessonId +
        '/exercises/' +
        exerciseId
    )
      .then((data) => {
        console.log('exercises successful', data)
        exerciseData = data
        recordingDetailStatus = 'ok'
      })
      .catch((error) => {
        console.log('error exercises', error)
      })
  }

  async function getRecording(recordingId) {
    getData('studentapi/publicrecordings/' + recordingId)
      .then((data) => {
        console.log('publicrecordings successful', data)
        recordingData = data

        recordingDataDetail = JSON.parse(recordingData?.dataString)
        recordingDataDetail['Display'] = recordingDataDetail.NBest[0].Display

        getExercise(
          recordingData?.courseId,
          recordingData?.lessonId,
          recordingData?.exerciseId
        )

        let filePath = recordingData?.fileName.endsWith('.ogg')
          ? 'https://www.googleapis.com/storage/v1/b/shuoshuo/o/recordings%2F' +
            recordingData?.fileName
          : 'https://www.googleapis.com/storage/v1/b/shuoshuo/o/recordings%2F' +
            recordingData?.fileName +
            '.wav'

        getDataOutside(filePath)
          .then((data2) => {
            console.log('recordingUrl', data2)
            if (!iOS) {
              recordingUrl = data2?.mediaLink
            } else {
              fetch(data2?.mediaLink).then((audioData) => {
                audioData.blob().then((audioBlob) => {
                  console.log(audioBlob)
                  processBlobToWav(audioBlob).then((audioResult) => {
                    console.log(audioResult)
                    recordingUrl = URL.createObjectURL(audioResult)
                  })
                })
              })
            }
          })
          .catch((error2) => {
            console.log('error get media', error2)
          })
      })
      .catch((error) => {
        console.log('error courses', error)
        recordingDetailStatus = 'error'
      })
  }

  function showDetailsForWords(w) {
    console.log('showDetailsForWords', w)
  }

  function classBasedOnScore(score) {
    if (score > 90) {
      return 'success'
    } else if (score > 70) {
      return 'primary'
    } else if (score > 50) {
      return 'warning'
    }
    return 'danger'
  }

  function printReport() {
    window.print()
  }
</script>

<div class="container-fluid">
  {#if recordingDetailStatus === 'loading'}
    <Loader />
  {:else}
    <h4>
      <span class="hidden">{recordingId}</span>
    </h4>
    <ol class="breadcrumb mb-4">
      <li
        class="breadcrumb-item"
        style="cursor:pointer"
        on:click={() => {
          router.goto('/course-list')
        }}
      >
        {$_('COURSE_LIST')}
      </li>
      {#if recordingData}
        <li
          class="breadcrumb-item"
          style="cursor:pointer"
          on:click={() => {
            router.goto('/course/' + recordingData.courseId)
          }}
        >
          {recordingData?.courseName}
        </li>
        <li
          class="breadcrumb-item"
          style="cursor:pointer"
          on:click={() => {
            router.goto('/lesson/' + recordingData.lessonId)
          }}
        >
          {recordingData?.lessonName}
        </li>
        <li
          class="breadcrumb-item"
          style="cursor:pointer"
          on:click={() => {
            router.goto('/exercise-open/' + recordingData.exerciseKey)
          }}
        >
          {recordingData?.exerciseTitle}
        </li>
      {/if}
    </ol>

    <div class="row">
      <div class="col-6 col-lg-3 mb-4">
        <div
          class="card border-left-{classBasedOnScore(
            recordingData?.overallScore
          )} shadow h-100 py-2"
        >
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-xs font-weight-bold text-primary text-uppercase mb-1"
                >
                  {$_('OVERALL_SCORE')}
                </div>
                <div class="row no-gutters align-items-center">
                  <div class="col-auto">
                    <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                      {formatNumberWith4Colors(
                        recordingData?.sensayWordScore,
                        formatNumberWith4Colors(recordingData?.overallScore)
                      )}
                    </div>
                  </div>
                  <div class="col">
                    <div class="progress progress-sm mr-2">
                      <div
                        class="progress-bar bg-{classBasedOnScore(
                          recordingData?.overallScore
                        )}"
                        role="progressbar"
                        style="width: {recordingData?.overallScore}%"
                        aria-valuenow={recordingData?.overallScore}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-clipboard-list fa-2x text-gray-300" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 col-lg-3 mb-4">
        <div
          class="card border-left-{classBasedOnScore(
            recordingData?.accuracyScore
          )} shadow h-100 py-2"
        >
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-xs font-weight-bold text-info text-uppercase mb-1"
                >
                  {$_('ACCURACY_SCORE')}
                </div>
                <div class="row no-gutters align-items-center">
                  <div class="col-auto">
                    <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                      {formatNumberWith4Colors(recordingData?.accuracyScore)}
                    </div>
                  </div>
                  <div class="col">
                    <div class="progress progress-sm mr-2">
                      <div
                        class="progress-bar bg-info"
                        role="progressbar"
                        style="width: {recordingData?.accuracyScore}%"
                        aria-valuenow={recordingData?.accuracyScore}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <i class="far fa-lightbulb fa-2x text-gray-300" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {#if exerciseData.type === 'READ'}
        <div class="col-6 col-lg-3 mb-4">
          <div
            class="card border-left-{classBasedOnScore(
              recordingData?.completenessScore
            )} shadow h-100 py-2"
          >
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div
                    class="text-xs font-weight-bold text-info text-uppercase mb-1"
                  >
                    {$_('COMPLETENESS_SCORE')}
                  </div>
                  <div class="row no-gutters align-items-center">
                    <div class="col-auto">
                      <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                        {formatNumberWith4Colors(
                          recordingData?.completenessScore
                        )}
                      </div>
                    </div>
                    <div class="col">
                      <div class="progress progress-sm mr-2">
                        <div
                          class="progress-bar bg-info"
                          role="progressbar"
                          style="width: {recordingData?.completenessScore}%"
                          aria-valuenow={recordingData?.completenessScore}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-compress-arrows-alt fa-2x text-gray-300" />
                </div>
              </div>
            </div>
          </div>
        </div>
      {/if}

      <div class="col-6 col-lg-3 mb-4">
        <div
          class="card border-left-{classBasedOnScore(
            recordingData?.fluencyScore
          )} shadow h-100 py-2"
        >
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-xs font-weight-bold text-info text-uppercase mb-1"
                >
                  {$_('FLUENCY_SCORE')}
                </div>
                <div class="row no-gutters align-items-center">
                  <div class="col-auto">
                    <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                      {formatNumberWith4Colors(recordingData?.fluencyScore)}
                    </div>
                  </div>
                  <div class="col">
                    <div class="progress progress-sm mr-2">
                      <div
                        class="progress-bar bg-info"
                        role="progressbar"
                        style="width: {recordingData?.fluencyScore}%"
                        aria-valuenow={recordingData?.fluencyScore}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-wind fa-2x text-gray-300" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 col-lg-3 mb-4">
        <div
          class="card border-left-{classBasedOnScore(
            recordingData?.pronScore
          )} shadow h-100 py-2"
        >
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-xs font-weight-bold text-info text-uppercase mb-1"
                >
                  {$_('PRONUNCIATION_SCORE')}
                </div>
                <div class="row no-gutters align-items-center">
                  <div class="col-auto">
                    <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                      {formatNumberWith4Colors(recordingData?.pronScore)}
                    </div>
                  </div>
                  <div class="col">
                    <div class="progress progress-sm mr-2">
                      <div
                        class="progress-bar bg-info"
                        role="progressbar"
                        style="width: {recordingData?.pronScore}%"
                        aria-valuenow={recordingData?.pronScore}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-crosshairs fa-2x text-gray-300" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {#if exerciseData.type === 'READ'}
        <div class="col-6 col-lg-3 mb-4">
          <div
            class="card border-left-{classBasedOnScore(
              recordingData?.similarityValue
            )} shadow h-100 py-2"
          >
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div
                    class="text-xs font-weight-bold text-info text-uppercase mb-1"
                  >
                    {$_('SIMILARITY_SCORE')}
                  </div>
                  <div class="row no-gutters align-items-center">
                    <div class="col-auto">
                      <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                        {formatNumberWith4Colors(
                          recordingData?.similarityValue
                        )}
                      </div>
                    </div>
                    <div class="col">
                      <div class="progress progress-sm mr-2">
                        <div
                          class="progress-bar bg-info"
                          role="progressbar"
                          style="width: {recordingData?.similarityValue}%"
                          aria-valuenow={recordingData?.similarityValue}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-crosshairs fa-2x text-gray-300" />
                </div>
              </div>
            </div>
          </div>
        </div>
      {/if}

      <div class="col-6 col-lg-3 mb-4">
        <div class="card border-left-dark shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-xs font-weight-bold text-warning text-uppercase mb-1"
                >
                  {$_('SPOKEN_WORD_COUNT')}
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  {formatNumberWith4Colors(recordingData?.wordCount)}
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-comments fa-2x text-gray-300" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 col-lg-3 mb-4">
        <div class="card border-left-dark shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-xs font-weight-bold text-warning text-uppercase mb-1"
                >
                  {$_('WORDS_PER_MINUTE')}
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  {#if recordingData?.wordPerMinute}
                    {recordingData?.wordPerMinute?.toFixed(1)}
                  {/if}
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-tachometer-alt fa-2x text-gray-300" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card shadow">
      <div class="card-header py-3 no-print" style="display: none;">
        <button class="btn btn-primary" on:click={printReport}
          ><i class="fa fa-print" style="padding-right: 8px;" />{$_('PRINT')}<br
          /></button
        >
      </div>
      <div class="card-body">
        <div>
          <table class="table">
            <tbody>
              <tr
                ><th scope="row">{$_('EXERCISE_TYPE')}</th><td>
                  {#if recordingData?.exerciseType}
                    {$_(`EXERCISE_TYPES.${recordingData?.exerciseType}`)}
                  {/if}</td
                ></tr
              >
              <tr
                ><th scope="row">{$_('RECORDING_TIME')}</th><td>
                  {#if recordingData?.createdAt}
                    {new Date(
                      Date.parse(recordingData?.createdAt + 'Z')
                    ).toLocaleString()}
                  {/if}</td
                ></tr
              >
              <tr>
                <th scope="row">{$_('DURATION')}</th>
                <td>
                  {#if recordingData?.durationSecond}
                    {convertSecToMin(recordingData?.durationSecond)}
                  {/if}
                </td>
              </tr>
              <tr>
                <th scope="row">{$_('AUDIO_RECORDING')}</th><td>
                  {#key recordingUrl}
                    {#if iOS}
                      <audio controls>
                        {#if iOS}
                          <source src={recordingUrl} type="audio/mpeg" />
                        {:else}
                          <source src={recordingUrl} type="audio/wave" />
                        {/if}

                        <track
                          default
                          kind="captions"
                          srclang="en"
                          src=""
                        />{$_('NOT_SUPPORTED')}</audio
                      >
                    {:else}
                      <audio controls src={recordingUrl}>
                        <source src={recordingUrl} type="audio/mp3" />
                        <source src={recordingUrl} type="audio/wave" />
                        <track
                          default
                          kind="captions"
                          srclang="en"
                          src=""
                        />{$_('NOT_SUPPORTED')}</audio
                      >
                    {/if}
                  {/key}
                </td></tr
              >
              {#if exerciseData.type === 'READ'}
                <tr>
                  <th scope="row">{$_('EXERCISE_TEXT')}</th><td
                    ><div>{exerciseData?.text}</div></td
                  >
                </tr>
              {:else if exerciseData.type === 'QUESTION'}
                <tr>
                  <th scope="row">{$_('EXERCISE_QUESTION')}</th><td
                    ><div>{exerciseData?.question}</div></td
                  >
                </tr>
              {/if}
              <tr
                ><th scope="row">{$_('RECOGNIZED_TEXT')}</th><td
                  ><pre
                    style="white-space: pre-wrap;">{recordingDataDetail?.Display}</pre></td
                ></tr
              >
              <!-- <tr
                ><th scope="row">{$_("REPORT_TYPE")}</th><td
                  ><pre
                    style="white-space: pre-wrap;">{exerciseData?.courseReportType}</pre></td
                ></tr
              >
              <tr
                ><th scope="row">{$_("SCORING_TYPE")}</th><td
                  ><pre
                    style="white-space: pre-wrap;">{exerciseData?.courseScoringType}</pre></td
                ></tr
              > -->

              <tr
                ><th scope="row">{$_('SCORE')}</th><td
                  >{#if exerciseData?.courseScoringType === 'SCORING_0_TO_100'}
                    <span
                      id="resultScoringOverallScore"
                      class={recordingData?.overallScoreLabel}
                      >{Number.parseFloat(recordingData?.overallScore).toFixed(
                        0
                      )}</span
                    >
                  {:else if exerciseData?.courseScoringType === 'SCORING_5_STARS'}
                    <span
                      class="fa fa-star"
                      class:checked={Number.parseFloat(
                        recordingData?.overallScore
                      ) > starLevels[1]}
                    />
                    <span
                      class="fa fa-star"
                      class:checked={Number.parseFloat(
                        recordingData?.overallScore
                      ) > starLevels[2]}
                    />
                    <span
                      class="fa fa-star"
                      class:checked={Number.parseFloat(
                        recordingData?.overallScore
                      ) > starLevels[3]}
                    />
                    <span
                      class="fa fa-star"
                      class:checked={Number.parseFloat(
                        recordingData?.overallScore
                      ) > starLevels[4]}
                    />
                    <span
                      class="fa fa-star"
                      class:checked={Number.parseFloat(
                        recordingData?.overallScore
                      ) > starLevels[5]}
                    />
                  {:else if exerciseData?.courseScoringType === 'SCORING_NO_SCORE'}
                    {#if Number.parseFloat(recordingData?.overallScore) > 30}
                      <h3>
                        Great job! <i class="fa fa-star checked" /><i
                          class="fa fa-star checked"
                        /><i class="fa fa-star checked" />
                      </h3>
                    {:else}
                      <h3>Hm…please try again!</h3>
                    {/if}
                  {/if}</td
                ></tr
              >
              {#if recordingData?.teacherScore}<tr
                  ><th scope="row">{$_('TEACHER_SCORE')}</th><td
                    >{recordingData?.teacherScore}</td
                  ></tr
                >{/if}
              {#if recordingData?.teacherLabel}<tr
                  ><th scope="row">{$_('TEACHER_LABEL')}</th><td
                    >{recordingData?.teacherLabel}</td
                  ></tr
                >{/if}
              {#if recordingData?.teacherComment}<tr
                  ><th scope="row">{$_('TEACHER_COMMENT')}</th><td
                    >{recordingData?.teacherComment}</td
                  ></tr
                >{/if}
            </tbody>
          </table>

          <form />
        </div>
      </div>
    </div>

    {#if recordingData?.exerciseType === 'QUESTION_FIX_ANSWER'}
      <div class="card shadow" style="margin-top: 10px">
        <div class="card-header" id="headingFive">
          <h4 class="mb-0">{$_('ANSWER_DETAIL')}</h4>
        </div>
        <div class="card-body">
          <QuestionWithAnswerDetail
            resultData={recordingData}
            {recordingDataDetail}
          />
        </div>
      </div>
    {:else}
      <div class="card shadow" style="margin-top: 10px">
        <div class="card-header" id="headingFive">
          <h4 class="mb-0">{$_('TEXT_ANALYZER')}</h4>
        </div>
        <div class="card-body">
          {#key recordingUrl || exerciseData.language}
            <PhonemeBreakdown
              {recordingUrl}
              language={exerciseData.language}
              resultData={recordingDataDetail}
            />
          {/key}
        </div>
      </div>
    {/if}

    <div class="card shadow" style="margin-top: 10px">
      <div class="card-header" id="headingFive">
        <h4 class="mb-0">{$_('TEACHING_COMMENTS')}</h4>
      </div>
      <div class="card-body">
          <RecordingTeachingComments
            recordingId={recordingId}
          />
      </div>
    </div>

  {/if}
</div>

<style>
  .excellent {
    color: #008154;
  }

  .good {
    color: #40ac86;
  }

  .poor {
    color: #f7ba31;
  }

  .bad {
    color: #fe5b5b;
  }

  .hidden {
    display: none;
  }

  .checked {
    color: #f6b95c !important;
  }
</style>
