<script>
  import { createEventDispatcher, onMount } from 'svelte'

  let recordings = []
  let recordAudio = []
  let recordCount = 0

  export let timeLimit

  onMount(async () => {
    checkPermissions()
    if (timeLimit) {
      time = timeLimit
    }
  })

  const workerOptions = {
    OggOpusEncoderWasmPath:
      'https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/OggOpusEncoder.wasm',
    WebMOpusEncoderWasmPath:
      'https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/WebMOpusEncoder.wasm',
  }

  window.MediaRecorder = OpusMediaRecorder
  let recorder

  // shim for AudioContext when it's not avb.
  let AudioContext = window.AudioContext || window.webkitAudioContext
  let audioContext //audio context to help us record

  let lastFilename
  let lastBlob
  let lastAudio

  let time = 30
  let remainingTime = time
  let countDownTimerObject
  let countDownTimerText = '0:30'

  export let recordingButtonState = 'starting'

  let permissionCheck = false

  const dispatch = createEventDispatcher()

  function checkPermissions() {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      devices.forEach((device) => {
        console.log('checkPermissions', device)
        if (device?.deviceId != '') {
          permissionCheck = true
        }
      })

      if (!permissionCheck) {
        console.error('No audio device detected - no permissions')
      }
    })
  }

  function startRecording() {
    if (recordingButtonState === 'starting') {
      mixpanel.track('Student Record Button Clicked')
    } else {
      mixpanel.track('Student Record Try Again Button Clicked')
    }

    remainingTime = time
    countDownTimerText = (remainingTime + '').padStart(2, '0') + 's'

    countDownTimerObject = setInterval(function () {
      var c = remainingTime--

      if (remainingTime < 0) {
        stopRecording()
        countDownTimerText = 'Finished'
      } else {
        countDownTimerText = (c + '').padStart(2, '0') + 's'
      }
    }, 1000)

    navigator.mediaDevices
      .getUserMedia({ audio: { channelCount: 1 }, video: false })
      .then((stream) => {
        let options = { mimeType: 'audio/ogg;codecs=opus' }
        // Start recording
        recorder = new MediaRecorder(stream, options, workerOptions)
        recorder.start()
        // Set record to <audio> when recording will be finished
        recorder.addEventListener('dataavailable', (e) => {
          console.log('dataavailable', e)

          createDownloadLink(e.data)
        })
        console.log('Recording started')
        recordingButtonState = 'recording'
        checkPermissions()
      })
      .catch(function (err) {
        //enable the record button if getUserMedia() fails
        checkPermissions()
        recordingButtonState = 'error'
        console.error('error processing audio', err)
      })
  }

  function stopRecording() {
    console.log('stopButton clicked')
    //disable the stop button, enable the record too allow for new recordings
    recordingButtonState = 'stop'

    try {
      clearInterval(countDownTimerObject)

      recorder.stop()
      // Remove “recording” icon from browser tab
      recorder.stream.getTracks().forEach((i) => i.stop())
    } catch (error) {
      console.error('Error stopRecording', error)
    }
  }

  function createDownloadLink(blob) {
    let url = URL.createObjectURL(blob)
    lastBlob = blob
    //var au = document.createElement('audio');
    //var li = document.createElement('div');

    //name of .wav file to use during upload and download (without extendion)
    let filename = new Date().toISOString()
    lastFilename = filename

    const newReocrding = { blob: blob, filename: filename, url: url }
    dispatch('message', newReocrding)
  }

  function clickSubmitCalculate() {
    console.log('clickSubmitCalculate')
  }
</script>

{#if recordingButtonState === 'starting' && !recordCount}
  <button class="btn btn-primary record" on:click={startRecording} type="button"
    ><i class="fa fa-microphone" style="margin: 0px 3px;" />Record</button
  >
{:else if recordingButtonState === 'stop'}
  <button
    class="btn btn-secondary try-again"
    on:click={startRecording}
    type="button"
  >
    <i class="fas fa-redo" style="padding: 0px 3px;" />
    Try Again
  </button>
{:else if recordingButtonState === 'recording'}
  <button
    class="btn btn-danger stop-record"
    on:click={stopRecording}
    type="button"
  >
    <i class="fa fa-stop" style="margin: 0px 3px;" />
    {countDownTimerText}
  </button>
{:else if recordingButtonState === 'error'}
  <i class="fas fa-exclamation-circle mr-1" />
  Please check if microphone is enabled.
{:else}
  N/A - {recordingButtonState}
{/if}

<style>
  .record {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: normal;
    font-size: 1.2em;
    line-height: 1.6em;

    border-radius: 16px;

    background: #00a094;
    border-color: #00a094;
    color: #ffffff;
  }

  .try-again {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: normal;
    font-size: 1.2em;
    line-height: 1.6em;

    border-radius: 16px;
    /* border-color: #CACACA; */

    /* background: #CACACA; */
    color: #000000;
  }

  .stop-record {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: normal;
    font-size: 1.2em;
    line-height: 1.6em;

    border-radius: 16px;

    background: #dd4040;
    border-color: #dd4040;
    color: #ffffff;
  }
</style>
